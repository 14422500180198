export default [
  {
    'featureType': 'administrative',
    'elementType': 'labels.text.fill',
    'stylers': [{
      'color': '#444444'
    }]
  },
  {
    'featureType': 'administrative.country',
    'elementType': 'labels.text',
    'stylers': [
      {
        'saturation': '18'
      },
      {
        'lightness': '-55'
      },
      {
        'visibility': 'simplified'
      },
      {
        'color': '#4484a1'
      }
    ]
  },
  {
    'featureType': 'landscape',
    'elementType': 'all',
    'stylers': [
      {
        'color': '#e9e8e6'
      },
      {
        'saturation': '28'
      },
      {
        'lightness': '42'
      },
      {
        'gamma': '2.01'
      },
      {
        'weight': '1'
      }
    ]
  },
  {
    'featureType': 'landscape.natural',
    'elementType': 'geometry.fill',
    'stylers': [{
      'color': '#f2f2f2'
    }]
  },
  {
    'featureType': 'landscape.natural.landcover',
    'elementType': 'all',
    'stylers': [{
      'color': '#f2f2f2'
    }]
  },
  {
    'featureType': 'landscape.natural.terrain',
    'elementType': 'all',
    'stylers': [
      {
        'color': '#f2f2f2'
      },
      {
        'visibility': 'off'
      }
    ]
  },
  {
    'featureType': 'poi',
    'elementType': 'all',
    'stylers': [{
      'visibility': 'off'
    }]
  },
  {
    'featureType': 'poi.park',
    'elementType': 'all',
    'stylers': [
      {
        'color': '#f2f2f2'
      },
      {
        'visibility': 'on'
      }
    ]
  },
  {
    'featureType': 'road',
    'elementType': 'all',
    'stylers': [
      {
        'saturation': -100
      },
      {
        'lightness': 45
      }
    ]
  },
  {
    'featureType': 'road.highway',
    'elementType': 'all',
    'stylers': [{
      'visibility': 'simplified'
    }]
  },
  {
    'featureType': 'road.arterial',
    'elementType': 'labels.icon',
    'stylers': [{
      'visibility': 'off'
    }]
  },
  {
    'featureType': 'transit',
    'elementType': 'all',
    'stylers': [{
      'visibility': 'off'
    }]
  },
  {
    'featureType': 'water',
    'elementType': 'all',
    'stylers': [
      {
        'color': '#7bb1c7'
      },
      {
        'visibility': 'on'
      }
    ]
  }
];
