export default () => {

  var ssecConfig = {
    jsPath: '/sites/all/themes/smithsonian_science/assets/js',
    videoConfig: {
      requirements : [],
      playerConfig : {
        controls: true,
      },
    },
    videos: {},
  };

  ssecConfig.videoConfig.requirements = [ ssecConfig.jsPath + '/vendor/video-js/video.dev.js' ];

  function includeJs(jsFilePath) {
      var js = document.createElement("script");
      js.type = "text/javascript";
      js.src = jsFilePath;
      document.body.appendChild(js);
  }

  // Enquire stuff
  Modernizr.load([
    {
      load: [ssecConfig.jsPath + "/vendor/enquire.min.js"],
      complete: function() {
        init();
      }
    },
  ]);

  function init() {
    ssecSearch();
    footerExtend();
    resourceGallerySlider('.node-type-resource');
    resourceMiracleGrow('.node-type-resource');
    // Trigger mobile menu enquire
    ssecMobileMenu();
    // Load Videos if they exist
    if ($('body').hasClass('node-type-resource')) {
      videoCheck('.field-gallery');
    } else {
      videoCheck('#content');
    }

    fitIFramesNonVideoJS();

    // Resources & Curriculum landing page
    facetAPICheckboxes();
    // Resources modal popup for Games and Apps
    resourceAppPopup();
    componentSliderPopup();
    formPopup();
    facetPulldown();

    smithMatchHeight();

    methodCircleHeightMatch();
    $(window).resize(function() {
      methodCircleHeightMatch();
    });
  }

  /**
   * Search bar stuff to position search icon correctly.
   * @return {[type]} [description]
   */
  function ssecSearch() {
    $('#header #edit-keywords').on('focus', function() {
      var width = $('#block-menu-block-main-menu .menu-block-wrapper > ul').width();
      $('.search-icon').css('right', width + 24);
      $('#edit-keywords').width(width + 50);
      ssecConfig.searchBlur = false;
    });

    $('#header #edit-keywords').on('blur', function() {
      if (ssecConfig.searchBlur === false) {
        var width = $('#block-menu-block-main-menu .menu-block-wrapper > ul').width();
        $('.search-icon').css('right', '');
        $('#edit-keywords').width('');
      }
    });

    $('#header .go-icon').on('click', function() {
      $('#edit-actions input.form-submit').trigger('click');
    });

    $(window).resize(function() {
      $('#header #edit-keywords').trigger('blur');
    });
  }

  function ssecMobileMenu() {

    enquire.register("screen and (max-width: 880px)", {

      deferSetup: true,

      setup: function() {

        // Clone and modify ID
        ssecConfig.menuApi = $('#block-menu-block-main-menu').clone();
        var search = $('#block-interactive-search-api-search').clone().attr('id', 'mobile-search'),
            secondary = $('#block-menu-block-secondary-menu .menu-block-wrapper').clone();

        ssecConfig.menuApi.children('.content').prepend(search).append(secondary);

        // $('#block-menu-block-secondary-menu .memu-block-wrapper').clone().appendTo()
        // Add some classes, change id, append to menu holster.
        ssecConfig.menuApi.attr('id', 'mobile-menu').addClass('closed');
        ssecConfig.menuApi.appendTo('#menu-holster');

        // Add toggles to submenus
        ssecConfig.menuApi.find('li.expanded').each(function(index, el) {
          el = $(el);
          var link = '<a class="l2-toggle" href="#"><span class="arrow">Expand</span></a>';
          el.children('ul.menu').before(link).hide();
        });

        // Level 2 submenu press function
        $('.l2-toggle').on('click touchstart', function(e) {
          e.preventDefault();

          $(this).siblings('ul.menu').slideToggle(200);

          if ($(this).hasClass('open')) {
            $(this).removeClass('open').find('span').text('Expand');
            $(this).parent('li').removeClass('open');
          } else {
            $(this).addClass('open').find('span').text('Shrink');
            $(this).parent('li').addClass('open');
          }
        });

      },

      match: function() {

        $('.mobile-trigger').on('click', function(e) {
          e.preventDefault();
          if (ssecConfig.menuApi.hasClass('closed')) {

            $(this).addClass('close-icon');
            ssecConfig.menuApi.removeClass('closed').addClass('open');

          } else {

            $(this).removeClass('close-icon');
            ssecConfig.menuApi.removeClass('open').addClass('closed');

          }

        });

      },

      unmatch: function() {

        if (ssecConfig.menuApi === undefined) {
          return false;
        }

        if (ssecConfig.menuApi.hasClass('open')) {
          ssecConfig.menuApi.removeClass('open').addClass('closed');
        }

        $('.mobile-trigger').off('click');

      }

    });
  }

  function resourceMiracleGrow(selector) {
    if (!$('body'+selector).length) {
      return false;
    }

    var grow = function(keepgoing) {
      if (keepgoing === false) {
        $('.angle-header-solid-wrapper').first().height('');
        $('.angle-header-solid').first().height('');
      } else {
        var height = $('.angle-resource-content').first().height() + 80;
        $('.angle-header-solid-wrapper').first().height(height);
        $('.angle-header-solid').first().height(height);
      }
    },
    run = debounce(grow);

    enquire.register("screen and (min-width: 990px)", {
      deferSetup: true,
      setup: function() {
        grow();
      },

      match: function() {
        $(window).bind('resize', run);
      },

      unmatch: function() {
        $(window).unbind('resize', run);

        setTimeout(function(){
          grow(false);
        }, 101);

      }
    });
  }

  /**
   * Make sure the footer is the correct height at all times.
   */
  function footerExtend() {

    $(document).ready(function() {

      enquire.register("screen and (min-width: 900px)", {

        match: function() {

          ssecConfig.footerpush = function(){
            if (($(window).height() - $('#footer').offset().top) > 0) {
              var padding = Number($('#footer').css('padding-top').replace(/[^-\d\.]/g, ''));
              $('#footer').height(Math.abs($(window).height() - $('#footer').offset().top - (padding*2)));
            }
          };

          var run = debounce(ssecConfig.footerpush);

          // Make footer extend
          if (($(window).height() - $('#footer').offset().top) > 0) {
            var padding = Number($('#footer').css('padding-top').replace(/[^-\d\.]/g, ''));
            $('#footer').height(Math.abs($(window).height() - $('#footer').offset().top - (padding*2)));
          }

          $(window).bind('resize', run);
        },

        unmatch: function() {
          $(window).unbind('resize', run);

          setTimeout(function() {
            $('#footer').height('');
          }, 100);
        }

      });

    });
  }


  /**
   * If there are videos on the page, load them with videoJS
   */
  function videoCheck(selector) {
    if(!$(selector).find('video').length) {
      return false;
    }

    $(selector).find('video').each(function(index, el) {

      if ( $(el).data('service') == 'youtube' && ssecConfig.videoConfig.requirements.indexOf('youtube') <= -1 ) {
        ssecConfig.videoConfig.requirements.push(ssecConfig.jsPath + '/vendor/vjs.youtube.js');
      }

      if ( $(el).data('service') == 'vimeo' && ssecConfig.videoConfig.requirements.indexOf('vimeo') <= -1 ) {
        ssecConfig.videoConfig.requirements.push(ssecConfig.jsPath + '/vendor/vjs.vimeo.js');
      }

    });

    Modernizr.load([{
      load: ssecConfig.videoConfig.requirements,
      complete: function() {

        $(selector).find('video').each(function(index, el) {

          var config = ssecConfig.videoConfig.playerConfig,
              id     = $(el).attr('id');
          config.techOrder = [$(el).data('service')];
          config.src = $(el).data('url');
          config.poster = $(el).data('poster');

          ssecConfig.videos[id] = videojs( el, config, function() {
          });

        });

      }
    }]);
  }

  // debouncing function from John Hann
  // http://unscriptable.com/index.php/2009/03/20/debouncing-javascript-methods/
  var debounce = function (func, threshold, execAsap) {

    var timeout;
    return function debounced () {
      var obj = this, args = arguments;
      function delayed () {
        if (!execAsap)
          func.apply(obj, args);
        timeout = null;
      }
      if (timeout)
        clearTimeout(timeout);
      else if (execAsap)
        func.apply(obj, args);
        timeout = setTimeout(delayed, threshold || 100);
    };
  };

  /**
   * Smart Resize function to debounce resize event.
   */
  $.fn.smartresize = function(fn){ return fn ? this.bind('resize', debounce(fn)) : this.trigger(sr); };


  /**
   * Loads gallery slider for resource and event pages
   */
  function resourceGallerySlider(selector) {

    // $(window).load(function() {
      if (!$(selector + ' .paragraphs-items-field-gallery').length) {
        return false;
      }

      enquire.register("screen and (min-width: 880px)", {

        deferSetup: false,
        setup: function() {

          Modernizr.load([{
            load: [ssecConfig.jsPath + '/vendor/flexslider/jquery.flexslider-min.js'],
            complete: function() {

              $(selector + ' .paragraphs-items-field-gallery ul.field-gallery').flexslider({
                selector: "li",
                controlNav: "thumbnails",
                controlsContainer: $('.node-type-resource .paragraphs-items-field-gallery'),
                slideshow: false,
                animationSpeed: 400,
                directionNav: false,
                video: true,
                smoothHeight: ($(window).width() > 880) ? false : true,
                start: function(slider){
                  // Just in case we need some styling
                  $(selector + ' .paragraphs-items-field-gallery').addClass('flexy');
                  if ($(selector + ' .paragraphs-items-field-gallery ul.field-gallery > li').length == 1) {
                    $(selector + ' .paragraphs-items-field-gallery').addClass('single-item');
                  }
                },
                before: function(slider){
                  pauseVideos();
                },

              });

              ssecConfig.flexslider = $(selector + ' .paragraphs-items-field-gallery ul.field-gallery').data('flexslider');

            } // Complete
          }]); // Modernizr

        },

        match: function() {
          if (ssecConfig.flexslider !== undefined && ssecConfig.flexslider.vars.smoothHeight === true) {
            ssecConfig.flexslider.vars.smoothHeight = false;
            ssecConfig.flexslider.setup();
          }
        },

        unmatch: function() {
          if (ssecConfig.flexslider !== undefined && ssecConfig.flexslider.vars.smoothHeight === false) {
            ssecConfig.flexslider.vars.smoothHeight = true;
            ssecConfig.flexslider.setup();
          }
        },

      });

    // });
  }

  /**
   * Helper function to disable any playing videos on the page.
   */
  function pauseVideos() {

    // Pause any videos on the page if they aren't being looked at.
    // if (ssecConfig.videos.length) {
    $.each(ssecConfig.videos, function(index, video) {
      if (!video.paused()) {
        video.pause();
      }
    });
    // }
  }

  /**
   * Corrects Facet API checkboxes to trigger query changes the right way.
   */
  function facetAPICheckboxes() {

    if ($('.facetapi-facetapi-checkbox-links').length) {
      $('.facetapi-facetapi-checkbox-links').find('input, span.facet-text').on('click touchstart', function(e) {
        e.stopPropagation();
        window.location.href = $(this).siblings('a').attr('href');
      });

    }

    if ($('.facetapi-facet-field-is-free').length) {
      $('.facetapi-facet-field-is-free').find('input, span.facet-text').on('click touchstart', function(e) {
        e.stopPropagation();
        window.location.href = $(this).siblings('a').attr('href');
      });
    }
  }

  /**
   * Loads video games and apps in a modal on resource detail view.
   */
  function resourceAppPopup() {
    $(document).ready(function() {

      if (!$('.ssec-modal').length) {
        return false;
      }

      Modernizr.load([{
        load: [ssecConfig.jsPath + '/vendor/mfp/jquery.magnific-popup.min.js'],
        complete: function() {
          $('.ssec-modal').magnificPopup({

            type: 'ajax',

            closeOnBgClick: false,
            closeBtnInside: false,
            showCloseBtn: true,

            callbacks: {
              open: function() {
                pauseVideos();
              },
            }

          });
        }
      }]);

    });
  }

  function componentSliderPopup() {
    $(document).ready(function() {

      if (!$('.interactive-component-parent .paragraphs-items-field-gallery').length) {
        return false;
      }

      Modernizr.load([{
        load: [ssecConfig.jsPath + '/vendor/mfp/jquery.magnific-popup.min.js'],
        complete: function() {

          $('.interactive-component-parent .paragraphs-items-field-gallery').find('a').magnificPopup({
            preloader: true,
            closeBtnInside: false,
            showCloseBtn: true,
            closeOnBgClick: true,
            type: 'image',
            gallery: {
              preload: [0,2],
              enabled: true,
            },
            image: {
              titleSrc: function(item) {
                return $(item.el).find('img').first().attr('title');
              },
              verticalFit: true,
            },

          });
        }
      }]);
    });
  }

  /**
   * Loads Magnific Popup for the contact form.
   */
  function formPopup() {
    $(document).ready(function() {

      if (!$('#contact-form-link').length) {
        return false;
      }

      Modernizr.load([{
        load: [ssecConfig.jsPath + '/vendor/mfp/jquery.magnific-popup.min.js'],
        complete: function() {
          $('#contact-form-link').magnificPopup({
            items: {
                src: '#ssec-structure-contact-us',
                type: 'inline'
            },

            closeOnBgClick: false,
            closeBtnInside: false,
            showCloseBtn: true,

            callbacks: {
              open: function() {
                pauseVideos();
              },
            }

          });
        }
      }]);

    });
  }

  /**
   * resource faucet filter pulldowns
   */
  function facetPulldown() {

    var facetLevelOne = $('.item-list h3');

    var facetLevelTwo = $('.facets').find('li.expanded').append('<span class="expand-trigger">expand</span>');

    $(facetLevelOne).click(function() {

      $(this).toggleClass('open');
      $(this).siblings('ul.facetapi-facetapi-checkbox-links').toggleClass('open');

    });

    //second level toggle
    $('.expand-trigger').click(function() {

      if ($(this).hasClass('open')) {
        $(this).removeClass('open').text('expand');
        $(this).parent('li').removeClass('open');
      } else {
        $(this).addClass('open').text('shrink');
        $(this).parent('li').addClass('open');
      }

    });

  }

  function methodCircleHeightMatch() {
    if($('.interactive-component-parent.style-alternating').length){
      var cw = $('.circle-image').width();
      $('.circle-image').css({
          'height': cw + 'px'
      });
    }
  }

  function smithMatchHeight() {

    Modernizr.load([{

        load: [ssecConfig.jsPath + "/vendor/jquery.matchHeight-min.js"],

        complete: function() {

          if ($('.style-training-services.bundle-two-up').length) {
            $('.two-up-wrapper').matchHeight(true);
          }

        },

      }]

    );

  }


  function fitIFramesNonVideoJS() {

    if ($('.field-body iframe').length ||
        $('.field-text iframe').length ||
        $('.field-description iframe').length) {
      Modernizr.load([{

          load: [ssecConfig.jsPath + "/vendor/fitvids/jquery.fitvids.js"],

          complete: function() {

            if ($('.field-body iframe').length) {
              $('.field-body').fitVids();
            }

            if ($('.field-text iframe').length) {
              $('.field-text').fitVids();
            }

            if ($('.field-description iframe').length) {
              $('.field-description').fitVids();
            }

          }

      }]);
    }
  }
};
