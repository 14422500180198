window.$ = jQuery;

// -----------------------------------------------------------------------------
// Component Includes
// -----------------------------------------------------------------------------
import general from './components/general';
import findSite from './components/find-site/find-site';

(($) => {

  // Document Ready
  $(() => {

    general();

    if ($('#map-container').length) {
      findSite();
    }

  });

})($);
