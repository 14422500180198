/* global google, process */
/* eslint no-unused-vars: ['error', { 'varsIgnorePattern': 'map' }] */

// Plugins
import get from '../../plugins/get';
import throttle from '../../plugins/throttle';
import getDistance from '../../plugins/get-distance';
import geocode from '../../plugins/geocode';
import mapStyles from './map-styles';
import regions from './regions';

export default () => {

  // paths
  const imagesPath = '/sites/all/themes/smithsonian_science/assets/images/';

  let map,
      mapBounds,
      mapCenter,
      numberSites,
      addressSearchValue,
      searchRadiusValue,
      filteredType;

  const drupal = Drupal;

  // empty array to store our map markers
  const markers = [];

  // empty array to store our map infowindows
  const infoWindows = [];

  // Map Options
  const mapOptions = {
    zoom: 1,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    disableDefaultUI: true,
    gestureHandling: 'cooperative',
    scrollwheel: false,
    styles: mapStyles
  };

  // Marker icon
  let markerIcon = {
    url: imagesPath + 'map-marker.svg',
    anchor: new google.maps.Point(12, 30),
    scaledSize: new google.maps.Size(24, 30),
    origin: new google.maps.Point(0, 0)
  };

  // Marker icon for search location
  const markerIconSearch = {
    url: imagesPath + 'map-marker-search.svg',
    anchor: new google.maps.Point(12, 12),
    scaledSize: new google.maps.Size(24, 24),
    origin: new google.maps.Point(0, 0)
  };

  function init() {

    //Node modules
    const SnazzyInfoWindow = require('snazzy-info-window');

    // project-specific variables
    const searchForm = $('.map-options-search'),
          searchInput = searchForm.find('input'),
          searchInputSelector = '.map-options-search input',
          searchRadius = searchForm.find('select'),
          searchRadiusSelector = '.map-options-search select',
          searchSubmit = searchForm.find('button'),
          searchSubmitSelector = '.map-options-search button',
          filterList = $('ul[data-filter=type]'),
          regionSelect = $('.map-header-selects-left'),
          stateSelect = $('.map-header-selects-right'),
          reset = $('.search-fields-container .reset');

    // Clear address field and disable search button on page load/refresh
    searchInput.val('');
    searchSubmit.attr('disabled', true);

    // Get radius on page load
    searchSubmit.attr('data-radius', searchRadius.val());

    $('html')
      // Disable/enable address search Go button depending on if field has value or not
      .on('input', searchInputSelector, (e) => {
        searchInput.removeClass('-invalid');
        if ($(e.currentTarget).val()) {
          searchSubmit.attr('disabled', false);
        } else {
          searchSubmit.attr('disabled', true);
        }
        searchSubmit.attr('data-address', $(e.currentTarget).val());
      })
      // Update radius data attribute when radius dropdown is changed
      .on('change', searchRadiusSelector, (e) => {
        searchSubmit.attr('data-radius', $(e.currentTarget).val());
      })
      // Trigger search when enter key is pressed
      .on('keypress', searchInputSelector, (e) => {
        if (e.keyCode === 13 && searchSubmit.attr('disabled') !== 'disabled') {
          searchSubmit.trigger('click');
        }
      });

    // Instantiate map with mapOptions
    map = new google.maps.Map(document.getElementById('map-container'), mapOptions);

    // Instantiate map bounds to draw the map based on markers
    mapBounds = new google.maps.LatLngBounds();

    // HTTP request with Promise to get sites
    get(drupal.settings.basePath + `ssec-locations`).then(JSON.parse).then((sites) => {

      const states = [];

      numberSites = sites.length;

      // Loop through the sites in the response
      sites.map((site, index) => {

        // add all states to array for dropdown
        states.push(site.state);

        // Coordinates of the community
        const siteLocation = new google.maps.LatLng(site.latitude, site.longitude);

        let infoWindowContent;

        // Determine which icon to use based on the site type and whether there is a success story
        if (site.successStoryTitle) {
          switch (site.type) {
            case 'Curriculum':
              markerIcon.url = imagesPath + 'map-marker-purple-story.svg';
              break;
            case 'Training & Services':
              markerIcon.url = imagesPath + 'map-marker-green-story.svg';
              break;
            case 'Partnerships':
              markerIcon.url = imagesPath + 'map-marker-yellow-story.svg';
              break;
          }
        } else {
          switch (site.type) {
            case 'Curriculum':
              markerIcon.url = imagesPath + 'map-marker-purple.svg';
              break;
            case 'Training & Services':
              markerIcon.url = imagesPath + 'map-marker-green.svg';
              break;
            case 'Partnerships':
              markerIcon.url = imagesPath + 'map-marker-yellow.svg';
              break;
          }
        }

        // Draw a marker for each random point
        const marker = new google.maps.Marker({
          position: siteLocation,
          icon: markerIcon,
          map,
          zIndex: index,
          animation: google.maps.Animation.DROP,
          draggable: false,
          optimized: false,
          data: {
            id: site.id,
            city: site.city,
            state: site.state,
            schoolDistrict: site.schoolDistrict,
            latitude: site.latitude,
            longitude: site.longitude,
            type: site.type,
            successStoryTitle: site.successStoryTitle,
            successStoryUrl: site.successStoryUrl
          }
        });

        // define our infowindows
        const infoWindow = new SnazzyInfoWindow({
          marker,
          map,
          closeWhenOthersOpen: true,
          shadow: false,
          pointer: false,
          border: false,
          position: siteLocation,
          offset: {
            top: '-56px',
            left: '0'
          },
          edgeOffset: {
            top: 25,
            right: 10,
            bottom: 25,
            left: 10
          },
          panOnOpen: false
        });

        // define our infowindow content based on whether there is a success story or not

        if (site.successStoryTitle) {
          infoWindowContent = `
            <div class="site-infowindow -success-story">
              <span class="ss-eyebrow">SUCCESS STORY</span>
              <span class="ss-title">${site.successStoryTitle}</span>
              <span class="site-infowindow-school-district">${site.schoolDistrict}</span>
              <span class="site-infowindow-city-state">${site.city}, ${site.state}</span>
              <a class="ss-link" href="${site.successStoryUrl}">READ SUCCESS STORY</a>
            </div>
          `;
        } else {
          infoWindowContent = `
            <div class="site-infowindow">
              <span class="site-infowindow-school-district">${site.schoolDistrict}</span>
              <span class="site-infowindow-city-state">${site.city}, ${site.state}</span>
            </div>
          `;
        }

        // set the content of our infowindows with what we stored
        infoWindow.setContent(infoWindowContent);

        // Add markers to array
        markers.push(marker);

        // push our markers and infowindows to an array so we can use them outside of this function
        infoWindows.push(infoWindow);

        // Bring marker to front and change color
        google.maps.event.addListener(marker, 'click', () => {
          markers.map(marker => {
            marker.setZIndex(index);
          });
          marker.setZIndex(google.maps.Marker.MAX_ZINDEX + sites.length);

          // Pan to the marker and offset the height of the infowindow
          infoWindow._callbacks = {
            afterOpen: () => {
              const offsetY = $('.site-infowindow').outerHeight() / 1.4 * -1;
              map.panTo(siteLocation);
              map.panBy(0, offsetY);
              mapCenter = map.getCenter();
            }
          };
        });

        // Extend markerBounds with each marker
        mapBounds.extend(siteLocation);
      });

      const regionSet = new Set();

      // add available states to stateSelect in alphabetical order
      const stateSet = new Set(states.sort());
      stateSet.forEach((state) => {
        const stateValue = state.toLowerCase().split(' ').join('');
        const option = $('<option></option>');
        option.val(stateValue).text(state);
        stateSelect.append(option);

        // determine corresponding region and add to regionSet
        for (let region in regions) {
          let array = regions[region];
          array.indexOf(stateValue) > -1 ? regionSet.add(region): '';
        };
      });

      regionSet.forEach((region) => {
        const option = $('<option></option>');
        option.val(region).text(region.toUpperCase());
        regionSelect.append(option);
      });

      // Draw the map with the boundaries from the markers
      map.fitBounds(mapBounds);

      // Set map center for resizing
      mapCenter = map.getCenter();

      // temporary array for storing searched address markers
      const searchMarkers = [];

      filterList.children().on('click', function() {
        filterList.find('.active').removeClass('active');
        $(this).addClass('active');
        filteredType = filterList.find('.active').children().text();

        // Clear map bounds to draw the map based on markers
        mapBounds = new google.maps.LatLngBounds();

        // remove markers that don't belong to the filter type
        if (filteredType === 'All') {
          markers.map(el => {
            el.setMap(map);
            mapBounds.extend(el.position);
          })
        } else if (filteredType === 'Success Stories') {
          markers.map(el => {
            if (el.data.successStoryTitle) {
              el.setMap(map);
              mapBounds.extend(el.position);
            } else {
              el.setMap(null);
            }
          })
        } else {
          markers.map(el => {
            if (el.data.type === filteredType) {
              el.setMap(map);
              mapBounds.extend(el.position);
            } else {
              el.setMap(null);
            }
          })
        }

        if (searchMarkers.length) {
          return;
        } else {
          map.fitBounds(mapBounds);

          // Make the maximum zoom 15
          if (map.getZoom() > 15) {
            map.setZoom(15);
          }
        }
      });

      regionSelect.on('change', e => {
        let visibleMarkers = false;

        // Clear map bounds to draw the map based on markers
        mapBounds = new google.maps.LatLngBounds();

        //reset state dropdown
        stateSelect.prop('selectedIndex', 0);

        if ($(e.currentTarget).val() === '*') {
          resetMap();
        } else {
          const currentValue = $(e.currentTarget).val();
          const regionStates = regions[currentValue];

          markers.map(el => {
            if (regionStates.indexOf(el.data.state.toLowerCase().split(' ').join('')) > -1) {
              el.data.inRadius = true;

              // filtering by all
              if ($('.map-options-list-item.-all').hasClass('active')) {
                el.setMap(map);
                mapBounds.extend(el.position);
                visibleMarkers = true;

                //filtering by type
              } else {

                /* eslint-disable no-lonely-if */

                // marker matches current selected type
                if (el.data.type.indexOf(filteredType) > -1) {
                  el.setMap(map);
                  mapBounds.extend(el.position);
                  visibleMarkers = true;

                // marker doesn't match current selected type
                } else {
                  el.setMap(null);
                }
                /* eslint-enable no-lonely-if */
              }
            } else {
              el.data.inRadius = false;
              el.setMap(null);
            }
          });

          searchMarkers.map(el => {
            el.setMap(null);
          });

          if (visibleMarkers) {
            map.fitBounds(mapBounds);
          } else {
            noResults();
          }

          // Make the maximum zoom 15
          if (map.getZoom() > 15) {
            map.setZoom(15);
          }
        }
      })

      //state dropdown functionality
      stateSelect.on('change', e => {
        let visibleMarkers = false;
        const currentValue = $(e.currentTarget).val();
        filteredType = filterList.find('.active').children().text();

        // Clear map bounds to draw the map based on markers
        mapBounds = new google.maps.LatLngBounds();

        //reset region dropdown
        regionSelect.prop('selectedIndex', 0);

        if ($(e.currentTarget).val() === '*') {
          resetMap();

        } else {

          // Loop through markers to check if they're within the state
          markers.map(el => {
            const elState = el.data.state.toLowerCase().split(' ').join('');

            // check if element's state matches selected state
            if (elState == currentValue) {
              el.data.inRadius = true;

              // filtering by all
              if ($('.map-options-list-item.-all').hasClass('active')) {
                el.setMap(map);
                mapBounds.extend(el.position);
                visibleMarkers = true;

                //filtering by type
              } else {

                /* eslint-disable no-lonely-if */

                // marker matches current selected type
                if (el.data.type.indexOf(filteredType) > -1) {
                  el.setMap(map);
                  mapBounds.extend(el.position);
                  visibleMarkers = true;

                // marker doesn't match current selected type
                } else {
                  el.setMap(null);
                }
                /* eslint-enable no-lonely-if */
              }
            } else {
              el.data.inRadius = false;
              el.setMap(null);
            }

          });

          searchMarkers.map(el => {
            el.setMap(null);
          });

          if (visibleMarkers) {
            map.fitBounds(mapBounds);
          } else {
            noResults();
          }

          // Make the maximum zoom 15
          if (map.getZoom() > 15) {
            map.setZoom(15);
          }
        }
      });


      searchSubmit.on('click', (e) => {
        let visibleMarkers = false;
        e.preventDefault();
        addressSearchValue = $(e.currentTarget).attr('data-address');
        searchRadiusValue = $(e.currentTarget).attr('data-radius');
        filteredType = filterList.find('.active').children().text();

        let lat,
            lng;

        // reset type, region, and state filterList
        stateSelect.add(regionSelect).prop('selectedIndex', 0);

        // show 'x' in search search
        reset.show();

        // Geocode address by address and pan to location
        geocode(addressSearchValue).then(function(result) {

          // Clear map bounds to draw the map based on markers
          mapBounds = new google.maps.LatLngBounds();

          const returnedLocation = result[0];

          lat = returnedLocation.geometry.location.lat();
          lng = returnedLocation.geometry.location.lng();

          // Draw a marker for searched address
          const searchMarker = new google.maps.Marker({
            position: returnedLocation.geometry.location,
            icon: markerIconSearch,
            zIndex: 9999999,
            animation: google.maps.Animation.DROP,
            draggable: false,
            optimized: false
          });

          // Add address search to array so we can set the map
          searchMarkers.push(searchMarker);

          searchMarkers.map(el => {
            // searched location
            if (el.position === returnedLocation.geometry.location) {
              el.setMap(map);
              // not searched location
            } else {
              el.setMap(null);
            }
          });

          /* eslint-enable indent */

          // Loop through markers to check if they're inside of the radius
          markers.map(el => {

            // Get distance between the marker and the searched location
            const distanceBetween = getDistance(el.data.latitude, el.data.longitude, lat, lng);

            // Check to see if a marker is inside of the radius
            if (distanceBetween <= searchRadiusValue) {
              el.data.inRadius = true;

              // filtering by all
              if ($('.map-options-list-item.-all').hasClass('active')) {
                el.setMap(map);
                mapBounds.extend(el.position);
                visibleMarkers = true;

                //filtering by type
              } else {

                /* eslint-disable no-lonely-if */

                // marker matches current selected type
                if (el.data.type.indexOf(filteredType) > -1) {
                  el.setMap(map);
                  mapBounds.extend(el.position);
                  visibleMarkers = true;

                // marker doesn't match current selected type
                } else {
                  el.setMap(null);
                }
                /* eslint-enable no-lonely-if */
              }
            // Outside of the radius so remove from the map
            } else {
              el.data.inRadius = false;
              el.setMap(null);
            }

          });

          const circ = new google.maps.Circle();
          circ.setRadius(searchRadiusValue * 1609.0);
          circ.setCenter(new google.maps.LatLng(lat, lng));
          map.setCenter(new google.maps.LatLng(lat, lng));

          if (!visibleMarkers) {
            noResults();
          }

          // Make the maximum zoom 15
          if (map.getZoom() > 15) {
            map.setZoom(15);
          }

        }, handleError);

      });

      reset.on('click', function() {
        const searchContainer = $('.search-fields-container');
        const searchInput = searchContainer.find('input');
        const radiusDropdown = searchContainer.find('select');

        // reset map and center on chicago
        resetMap();

        // reset search markers
        searchMarkers.map((el) => {
          el.setMap(null);
        });

        // reset search input and dropdown, remove reset 'x'
        searchInput.val('');
        radiusDropdown.prop('selectedIndex', 1);
        reset.hide();
      });

      const handleError = status => {
        let stat = google.maps.GeocoderStatus;

        // Handle errors
        if (status == stat.ZERO_RESULTS || status == stat.INVALID_REQUEST || status == stat.UNKOWN_ERROR) {
          console.log(status);
        }

        /* eslint-enable indent */

        // Remove all markers from the map since we didn't get a good response
        markers.map((el) => {
          el.setMap(null);
        });
        searchMarkers.map((el) => {
          el.setMap(null);
        });

        map.setZoom(7);
        map.panTo(new google.maps.LatLng(39.230498, -94.588577));
      };

      const clusterStyles = [
        {
          textColor: '#ffffff',
          height: 53,
          width: 53,
          url: imagesPath + 'm1.png',
          textSize: 14
        },
        {
          textColor: '#ffffff',
          height: 56,
          width: 56,
          url: imagesPath + 'm2.png',
          textSize: 14
        },
        {
          textColor: '#ffffff',
          height: 66,
          width: 66,
          url: imagesPath + 'm3.png',
          textSize: 14
        },
        {
          textColor: '#ffffff',
          height: 78,
          width: 78,
          url: imagesPath + 'm4.png',
          textSize: 14
        },
        {
          textColor: '#ffffff',
          height: 90,
          width: 90,
          url: imagesPath + 'm5.png',
          textSize: 14
        },
      ]

      const mcOptions = {
        styles: clusterStyles
      }

      const markerCluster = new MarkerClusterer(map, markers, mcOptions);
    });

    // Custom Zoom Controls
    const zoomControlDiv = document.createElement('div');
    zoomControlDiv.className = 'map-zoom-controls';
    new ZoomControl(zoomControlDiv, map);
    zoomControlDiv.index = 1;
    map.controls[google.maps.ControlPosition.TOP_RIGHT].push(zoomControlDiv);

    // center map on resize
    google.maps.event.addDomListener(window, 'resize', () => {
      google.maps.event.trigger(map, 'resize');
      map.setCenter(mapCenter);
    });

    // reset state and region selects
    function noResults() {
      stateSelect.prop('selectedIndex', 0);
      regionSelect.prop('selectedIndex', 0);
      $('.no-results').fadeIn('fast').delay(1000).fadeOut('slow');
    }

  }

  // Init map on window.load
  google.maps.event.addDomListener(window, 'load', init);

  // Custom zoom controls
  function ZoomControl(controlDiv, map) {

    // Create control wrapper
    const controlWrapper = document.createElement('div');
    controlWrapper.className = 'map-zoom-controls-inner';
    controlDiv.appendChild(controlWrapper);

    // Create zoomIn button
    const zoomInButton = document.createElement('a');
    zoomInButton.className = 'map-zoom-controls-zoom-in';
    zoomInButton.href = '#';
    zoomInButton.textContent = '+';
    controlWrapper.appendChild(zoomInButton);

    // Create zoomOut button
    const zoomOutButton = document.createElement('a');
    zoomOutButton.className = 'map-zoom-controls-zoom-out';
    zoomOutButton.href = '#';
    zoomOutButton.textContent = '-';
    controlWrapper.appendChild(zoomOutButton);

    // Setup the click event listener - zoomIn
    google.maps.event.addDomListener(zoomInButton, 'click', (e) => {
      e.preventDefault();
      $(e.currentTarget).trigger('blur');
      map.setZoom(map.getZoom() + 1);
    });

    // Setup the click event listener - zoomOut
    google.maps.event.addDomListener(zoomOutButton, 'click', (e) => {
      e.preventDefault();
      $(e.currentTarget).trigger('blur');
      map.setZoom(map.getZoom() - 1);
    });
  }

  // reset map to center
  function resetMap() {
    markers.map(el => {
      el.setMap(map);
    });
    map.panTo(new google.maps.LatLng(39.230498, -94.588577));
    map.setZoom(4);
  }

  // add expanded class for nominal styling and toggle search options expansion
  const container = $('.map-options-search');
  const searchToggle = $('.show-search');
  const searchFields = container.find('.search-fields-container');

  searchToggle.on('click', function() {
    container.toggleClass('expanded');
    searchFields.slideToggle({duration: 500, easing: 'linear'});
  });
};
