/* global google */

// geocode address with jquery promise
export default (address) => {
  const geocoder = new google.maps.Geocoder();
  const def = $.Deferred(); // eslint-disable-line new-cap

  geocoder.geocode({
    address
  }, (results, status) => {

    if (status === google.maps.GeocoderStatus.OK) {
      def.resolve(results);
    } else {
      def.reject(status);
    }
  });
  return def.promise();
};
