export default {
  'northeast': [
    'connecticut',
    'maine',
    'massachusetts',
    'newhampshire',
    'newjersey',
    'newyork',
    'pennsylvania',
    'rhodeisland',
    'vermont',
    'districtofcolumbia'
  ],
  'south': [
    'alabama',
    'arkansas',
    'delaware',
    'florida',
    'georgia',
    'kentucky',
    'louisiana',
    'maryland',
    'mississippi',
    'northcarolina',
    'oklahoma',
    'southcarolina',
    'tennessee',
    'texas',
    'virginia',
    'westvirginia'
  ],
  'west': [
    'alaska',
    'arizona',
    'california',
    'colorado',
    'hawaii',
    'idaho',
    'montana',
    'nevada',
    'newmexico',
    'oregon',
    'utah',
    'washington',
    'wyoming'
  ],
  'midwest': [
    'illinois',
    'indiana',
    'iowa',
    'kansas',
    'michigan',
    'minnesota',
    'missouri',
    'nebraska',
    'northdakota',
    'ohio',
    'southdakota',
    'wisconsin'
  ]
}
